<template>
  <div class="dashboard p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
          <h1 class="intro-section-title">Diagnose - Wonen</h1>
          <div class="row">
            <p>
              Onderstaand dashboard toont informatie over wonen als onderdeel van de levenspadfase Diagnose. Er wordt informatie gegeven over de woonsituatie van mensen met dementie en de tijd die er gemiddeld zit tussen indicatie van dementie en de opname van de persoon met dementie in een zorginstelling. Het overzicht wordt gegeven met zowel landelijke als regionale cijfers op het niveau van zorgkantoorregio’s en Dementienetwerken. Dit overzicht gaat over gegevens uit de jaren 2015-2022. 
            </p>
          </div>
      </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Woonsituatie mensen met dementie in ${selectedYear}`" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Het aantal mensen dat in een zorginstelling woont komt niet overeen met het aantal mensen dat WLZ-zorg met verblijf ontvangt dat elders gerapporteerd wordt. Dat komt omdat voor deze grafiek gebruik wordt gemaakt van een andere bron: het Basisregister Personen (BRP). Pas als iemand een verhuizing naar een zorginstelling doorgeeft wordt hij/zij als bewoner van een zorginstelling aangemerkt. Daarnaast dient de locatie van de zorginstelling aangemerkt als zodanig aangemerkt te zijn in het BRP. Tot slot nemen we hier de huishoudenssamenstelling op 1 januari als uitgangspunt en wordt dus iedereen die gedurende het jaar naar een zorginstelling verhuist niet meegenomen in deze cijfers.'" 
          :x="regionaam_huishouden " :y="typehuishouden_alle" :z="percentage_huishouden_perc.map(value => `${value}%`)"
          :xtitle="'Region naam'" :ytitle="'Type huishouden'" :ztitle="'Percentage'"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 4300 : 2300">
          <template slot="card-header">Woonsituatie mensen met dementie in {{selectedYear}}</template>
          <div slot="card-body">
            <div class="text">
            In onderstaand diagram is te zien hoe het geselecteerde netwerk/zorgkantoor zich verhoudt tot het landelijk gemiddelde op het gebied van woonsituatie van mensen met dementie.
            </div>
            <div class="row fluid">
                <div class="col-sm-7">
                  <stacked-bar-chart-colors v-if="load.graphOne"
                  :ytitle="'% woonsituatie'"
                  :xtitle="''"
                  :names="['Zorginstelling', 'Meerpersoons Huishouden', 'Eenpersoons Huishouden']"
                  :x="[zorgkantoorregionaam_zi, zorgkantoorregionaam_mp,zorgkantoorregionaam_ep]"
                  :y="[zorginstelling, meerpersoons, eenpersoons]"
                  :ticksuffix="'%'"
                  :title="'Regio Benchmark'"/>
                </div>
                <div class="col-sm-5">
                    <stacked-bar-chart-colors-multi v-if="load.graphTwo" :tickangle='0'
                    :name3="'Eenpersoons Huishouden'"
                    :x3="ddt_zorgkantoorregionaam_ep"
                    :xx3="ddt_jaar_ep"
                    :y3="ddt_eenpersoons"
                    :name2="'Meerpersoons Huishouden'"
                    :x2="ddt_zorgkantoorregionaam_mp"
                    :xx2="ddt_jaar_mp"
                    :y2="ddt_meerpersoons"
                    :name1="'Zorginstelling'"
                    :x1="ddt_zorgkantoorregionaam_zi"
                    :xx1="ddt_jaar_zi"
                    :y1="ddt_zorginstelling" />
                </div>
            </div>
          </div>
        </card>
      </div>
      </div>
      <div class="row" v-if="selectedLevel !== 'landelijk'">
        <div class="col-sm-12 down">
          <card :cardId="`Tijd tussen Wlz-indicatie en verhuizing zorginstelling in ${selectedYear}`" v-if="selectedLevel==='zorgkantoor'" :hovertext="'Er wordt uitgegaan van de eerste indicatie. Om te bepalen of een indicatie de eerste was worden ook indicaties voor zorg met verblijf die afgegeven zijn in de periode 2012-2014 meegenomen.'" 
            :x="jaar_tijdtotopname" :y="regionaam_tijdtotopname" :z="tijdtotopname_alle " :w="percentage_tijdtotopname.map(value => `${value}%`)"
            :xtitle="'Jaar'" :ytitle="'Region naam'" :ztitle="'Tijd tot opname'" :wtitle="'Percentage'" 
            :tableHeight='3900'>
            <template slot="card-header">Tijd tussen Wlz-indicatie en verhuizing zorginstelling in {{selectedYear}}</template>
            <div slot="card-body">
              Onderstaande grafiek geeft de (wacht)tijd weer tussen de indicatie en de verhuizing naar een zorginstelling voor mensen met dementie die voor het eerst een indicatie kregen voor zorg met verblijf. Mensen die vóór opname overleden vallen in de groep "geen opname". Op landelijk niveau is te zien dat het percentage mensen dat binnen 42 dagen opgenomen wordt vanaf 2017 afneemt. Deze informatie is alleen op zorgkantoor beschikbaar.
              <stacked-bar-chart-colors v-if="load.graphThree" 
              :ytitle="''"
              :height="500" 
              :xtitle="''"
              :names="['0-42 dagen', '43-91 dagen', '92-180 dagen', '181-365 dagen', '365+ dagen/geen opname']"
              :x="[zorgkantoorregio42, zorgkantoorregio91, zorgkantoorregio180, zorgkantoorregio365, zorgkantoorregio366n]"
              :y="[dagen42, dagen91, dagen180, dagen365, dagen366n]"
              :ticksuffix="'%'"
              />
            </div>
          </card>
          <card-small :cardId="'diagnose-wonen-2-1'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Er wordt uitgegaan van de eerste indicatie. Om te bepalen of een indicatie de eerste was worden ook indicaties voor zorg met verblijf die afgegeven zijn in de periode 2012-2014 meegenomen.'">
            <template slot="card-header">Tijd tussen Wlz-indicatie en verhuizing zorginstelling - uitsluitend op zorgkantoorregio-niveau</template>
          </card-small>
        </div>
      </div>

      <div class="row" v-if="selectedLevel === 'landelijk'">
          <div class="col-sm-12 down">
            <card :cardId="'Woonsituatie mensen met dementie'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Het aantal mensen dat in een zorginstelling woont komt niet overeen met het aantal mensen dat WLZ-zorg met verblijf ontvangt dat elders gerapporteerd wordt. Dat komt omdat voor deze grafiek gebruik wordt gemaakt van een andere bron: het Basisregister Personen (BRP). Pas als iemand een verhuizing naar een zorginstelling doorgeeft wordt hij/zij als bewoner van een zorginstelling aangemerkt. Daarnaast dient de locatie van de zorginstelling aangemerkt als zodanig aangemerkt te zijn in het BRP. Tot slot nemen we hier de huishoudenssamenstelling op 1 januari als uitgangspunt en wordt dus iedereen die gedurende het jaar naar een zorginstelling verhuist niet meegenomen in deze cijfers.'" 
              :x="jaar_huishouden_alle" :y="huishouden_groep_alle" :z="percentage_huishouden_alle.map(value => `${value}%`)"
              :xtitle="'Jaar'" :ytitle="'Huishouden groep'" :ztitle="'Percentage'" 
              :tableHeight="520">
                <template slot="card-header">Woonsituatie mensen met dementie</template>
                <div slot="card-body">
                  Onderstaand schema geeft het landelijke niveau weer van de leefsituatie van mensen met dementie.
                    <stacked-bar-chart-colors-multi v-if="load.graphSix"
                    :tickangle='0'
                    :isLandelijk='false'
                    :height= "360"
                    :name3="'Eenpersoons Huishouden'"
                    :x3="zorgkantoorregionaam_ep_land"
                    :xx3="ddt_jaar_ep_land"
                    :y3="eenpersoons_land"
                    :name2="'Meerpersoons Huishouden'"
                    :x2="zorgkantoorregionaam_mp_land"
                    :xx2="ddt_jaar_mp_land"
                    :y2="meerpersoons_land"
                    :name1="'Zorginstelling'"
                    :x1="zorgkantoorregionaam_zi_land"
                    :xx1="ddt_jaar_i_land"
                    :y1="zorginstelling_land" />
                  </div>
                </card>
              </div>
            </div>

        <div class="row" v-if="selectedLevel === 'landelijk'">
          <div class="col-sm-12 down">
            <card :cardId="'Tijd tussen Wlz-indicatie en verhuizing zorginstelling'" :hovertext="'Er wordt uitgegaan van de eerste indicatie. Om te bepalen of een indicatie de eerste was worden ook indicaties voor zorg met verblijf die afgegeven zijn in de periode 2012-2014 meegenomen.'" 
              :x="jaar_tijdtotopname_alle" :y="tijdtotopname_groep_alle" :z="percentage_tijdtotopname_alle.map(value => `${value}%`)"
              :xtitle="'Jaar'" :ytitle="'Tijd tot opname'" :ztitle="'Percentage'" 
              :tableHeight="700">
                <template slot="card-header">Tijd tussen Wlz-indicatie en verhuizing zorginstelling</template>
                <div slot="card-body">
                  Onderstaande grafiek geeft landelijk het niveau weer van de (wacht)tijd tussen de indicatie en de verhuizing naar een zorginstelling voor mensen met dementie die voor het eerst een indicatie kregen voor residentiële zorg. Mensen die vóór opname zijn overleden, vallen in de groep 'geen toegang'. Op landelijk niveau is te zien dat het percentage mensen dat binnen 42 dagen wordt toegelaten, is afgenomen ten opzichte van 2017.
                    <stacked-bar-chart-colors-multi v-if="load.graphSix"
                    :tickangle='0'
                    :isLandelijk='false'
                    :height= "360"
                    :name5="'0-42 dagen'"
                    :x5="zorgkantoorregio42_land"
                    :xx5="ttp_jaar_42_land"
                    :y5="dagen42_land"
                    :name4="'43-91 dagen'"
                    :x4="zorgkantoorregio91_land"
                    :xx4="ttp_jaar_91_land"
                    :y4="dagen91_land"
                    :name3="'92-180 dagen'"
                    :x3="zorgkantoorregio180_land"
                    :xx3="ttp_jaar_180_land"
                    :y3="dagen180_land"
                    :name2="'181-365 dagen'"
                    :x2="zorgkantoorregio365_land"
                    :xx2="ttp_jaar_365_land"
                    :y2="dagen365_land"
                    :name1="'> 365 dagen/geen opname'"
                    :x1="zorgkantoorregio366n_land"
                    :xx1="ttp_jaar_366n_land"
                    :y1="dagen366n_land" />
                  </div>
                </card>
              </div>
            </div>


      <div class="row">
        <div class="col-sm-6 down">
          <card :cardId="'Herkomst voor ziekenhuisopname nationale cijfers'" :hovertext="'Waar verbleef de patient voor de ziekenhuisopname (volgens het ziekenhuis): thuis, in een verpleeghuis of elders. In deze grafiek wordt uitsluitend gekeken naar mensen met dementie.'" 
            :x="jaar_opnameherkomst" :y="opnameherkomst_alle" :z="percentage_opnameherkomst.map(value => `${value}%`)"
            :xtitle="'Jaar'" :ytitle="'Opname herkomst'" :ztitle="'Percentage'" 
            :tableHeight="420">
            <template slot="card-header">Herkomst voor ziekenhuisopname, nationale cijfers</template>
            <div slot="card-body">
                <p class="graphtext">Onderstaande grafiek geeft inzicht in de herkomst van mensen voordat ze worden opgenomen in het ziekenhuis </p>
                <stacked-bar-chart-colors-x-t v-if="load.graphFour" :ytitle="'% herkomst'" :xtitle="'Jaar'"
                :name1="'Verpleeghuis'"
                :x1="jaar_verpleeghuis"
                :y1="verpleeghuis"
                :name2="'Thuis'"
                :x2="jaar_thuis"
                :y2="thuis"
                :name3="'Overig'"
                :x3="jaar_overig"
                :y3="overig"
                :marginBottom="50"
                :tickangle='0'/>
            </div>
          </card>
        </div>
        <div class="col-sm-6 down">
          <card :cardId="'Bestemming na ziekenhuisopname nationale cijfers'" :hovertext="'Waar verbleef de patient na de ziekenhuisopname (volgens het ziekenhuis): thuis, in een verpleeghuis, hospice, revalidatiecentrum of elders. In deze grafiek wordt uitsluitend gekeken naar mensen met dementie.'" 
            :x="jaar_opnamebestemming" :y="opnamebestemming_alle" :z="percentage_opnamebestemming.map(value => `${value}%`)"
            :xtitle="'Jaar'" :ytitle="'Opname bestemming'" :ztitle="'Percentage'" 
            :tableHeight="540">
            <template slot="card-header">Bestemming na ziekenhuisopname, nationale cijfers</template>
            <div slot="card-body">
                <p class="graphtext">Onderstaande grafiek geeft inzicht in de bestemming van mensen nadat ze zijn opgenomen in het ziekenhuis </p>
                <stacked-bar-chart-colors-x-t v-if="load.graphFive" :ytitle="'% bestemming'" :xtitle="'Jaar'"
                :name1="'Verpleeghuis'"
                :x1="jaar_verpleeghuis_bst"
                :y1="verpleeghuis_bst"
                :name2="'Thuis'"
                :x2="jaar_thuis_bst"
                :y2="thuis_bst"
                :name3="'Hospice'"
                :x3="jaar_hospice_bst"
                :y3="hospice_bst"
                :name4="'Revalidatiecentrum'"
                :x4="jaar_revalidatiecentrum_bst"
                :y4="revalidatiecentrum_bst"
                :name5="'Overig'"
                :x5="jaar_overig_bst"
                :y5="overig_bst"
                :marginBottom="50"
                :tickangle='0'/>
            </div>
          </card>
        </div>
      </div>

      <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>
<script>

import StackedBarChartColors from '../components/StackedBarChartColors'
import StackedBarChartColorsMulti from '../components/StackedBarChartColorsMulti'
import StackedBarChartColorsXT from '../components/StackedBarChartColorsXTitle'
import Card from '../components/Card'
import CardSmall from '../components/CardSmall'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta';

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Diagnose - Wonen',
  },
  components: { StackedBarChartColors, Card, CardSmall, StackedBarChartColorsMulti, StackedBarChartColorsXT, Footer },
  data () {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      zorgkantoorregionaam_ep: [],
      zorgkantoorregionaam_mp: [],
      zorgkantoorregionaam_zi: [],
      zorgkantoorregionaam_ep_land: [],
      zorgkantoorregionaam_mp_land: [],
      zorgkantoorregionaam_zi_land: [],
      eenpersoons: [],
      meerpersoons: [],
      zorginstelling: [],
      eenpersoons_land: [],
      meerpersoons_land: [],
      zorginstelling_land: [],
      ttp_jaar_42_land: [], 
      ttp_jaar_91_land: [], 
      ttp_jaar_180_land: [], 
      ttp_jaar_365_land: [], 
      ttp_jaar_366n_land: [],
      ddt_zorgkantoorregionaam_ep: [],
      ddt_zorgkantoorregionaam_mp: [],
      ddt_zorgkantoorregionaam_zi: [],
      ddt_eenpersoons: [],
      ddt_meerpersoons: [],
      ddt_zorginstelling: [],
      ddt_jaar_mp: [],
      ddt_jaar_ep: [],
      ddt_jaar_i: [],
      ddt_jaar_mp_land: [],
      ddt_jaar_ep_land: [],
      ddt_jaar_i_land: [],
      jaar_huishouden_alle: [],
      percentage_huishouden_alle: [],
      huishouden_groep_alle: [],
      jaar_huishouden: [],
      regionaam_huishouden: [],
      typehuishouden_alle :[],
      percentage_huishouden : [],
      percentage_huishouden_perc:[],
      tijdtotopname_groep_alle : [],
      jaar_tijdtotopname_alle : [], 
      percentage_tijdtotopname_alle : [],
      jaar_tijdtotopname: [],
      regionaam_tijdtotopname: [], 
      tijdtotopname_alle: [],
      percentage_tijdtotopname: [],
      jaar_opnamebestemming: [],
      opnamebestemming_alle: [],
      percentage_opnamebestemming: [],
      jaar_opnameherkomst: [],
      opnameherkomst_alle: [],
      percentage_opnameherkomst: [],
      load: {
        graphOne:false,
        graphTwo:false,
        graphThree:false,
        graphFour:false,
        graphFive:false,
        graphSix: false
      },
      zorgkantoorregio42_land: [],
      dagen42_land: [],
      zorgkantoorregio91_land: [],
      dagen91_land: [],
      zorgkantoorregio365_land: [],
      dagen365_land: [],
      zorgkantoorregio180_land: [],
      dagen180_land: [],
      zorgkantoorregio366n_land: [],
      dagen366n_land: [],


      zorgkantoorregio42: [],
      dagen42: [],
      zorgkantoorregio91: [],
      dagen91: [],
      zorgkantoorregio365: [],
      dagen365: [],
      zorgkantoorregio180: [],
      dagen180: [],
      zorgkantoorregio366n: [],
      dagen366n: [],
      thuis: [],
      verpleeghuis: [],
      overig: [],
      jaar_thuis: [],
      jaar_verpleeghuis: [],
      jaar_overig: [],
      thuis_bst: [],
      verpleeghuis_bst: [],
      overig_bst: [],
      hospice_bst: [],
      revalidatiecentrum_bst: [],
      jaar_thuis_bst: [],
      jaar_verpleeghuis_bst: [],
      jaar_hospice_bst: [],
      jaar_revalidatiecentrum_bst: [],
      jaar_overig_bst: [],
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
      this.loadTijdTotOpname()
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadTijdTotOpname () {
      this.$http.get(`/api/zorgkantoor_tijdtotopname/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a, b) => a.zorgkantoorregionaam - b.zorgkantoorregionaam )
            this.jaar_tijdtotopname = response.data.map(d => d.jaar)
            this.regionaam_tijdtotopname = response.data.map(d => d.zorgkantoorregionaam)
            this.tijdtotopname_alle = response.data.map(d => d.tijd_tot_opname)
            this.percentage_tijdtotopname = response.data.map(d => (d.percentage).toFixed(1))


          response.data.sort((a,b) => b.percentage-a.percentage)
          response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
          this.zorgkantoorregio42 = response.data.filter(x => x.tijd_tot_opname =="0-42 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen42 = response.data.filter(x => x.tijd_tot_opname =="0-42 dagen").map(d => (d.percentage))
          this.zorgkantoorregio91 = response.data.filter(x => x.tijd_tot_opname =="43-91 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen91 = response.data.filter(x => x.tijd_tot_opname =="43-91 dagen").map(d => (d.percentage))
          this.zorgkantoorregio180 = response.data.filter(x => x.tijd_tot_opname =="92-180 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen180 = response.data.filter(x => x.tijd_tot_opname =="92-180 dagen").map(d => (d.percentage))
          this.zorgkantoorregio365 = response.data.filter(x => x.tijd_tot_opname =="181-365 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen365 = response.data.filter(x => x.tijd_tot_opname =="181-365 dagen").map(d => (d.percentage))
          this.zorgkantoorregio366n = response.data.filter(x => x.tijd_tot_opname =="> 365 dagen/geen opname").map(d => d.zorgkantoorregionaam)
          this.dagen366n = response.data.filter(x => x.tijd_tot_opname =="> 365 dagen/geen opname").map(d => (d.percentage))
          this.load.graphThree=true
        }
      )
    },
    loadAllData (level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_huishouden/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a, b) => a.zorgkantoorregionaam - b.zorgkantoorregionaam )
            this.jaar_huishouden = response.data.map(d => d.jaar)
            this.regionaam_huishouden = response.data.map(d => d.zorgkantoorregionaam)
            this.typehuishouden_alle = response.data.map(d => d.typehuishouden)
            this.percentage_huishouden = response.data.map(d => d.percentage.toFixed(4))
            this.percentage_huishouden_perc = response.data.map(d => (d.percentage).toFixed(1))
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.zorgkantoorregionaam_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.zorgkantoorregionaam)
            this.eenpersoons = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => (d.percentage))
            this.zorgkantoorregionaam_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.zorgkantoorregionaam)
            this.meerpersoons = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => (d.percentage))
            this.zorgkantoorregionaam_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.zorgkantoorregionaam)
            this.zorginstelling = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => (d.percentage))
            this.load.graphOne=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_huishouden/get_data/?regio=${this.$store.state.selectedRegion}`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.ddt_zorgkantoorregionaam_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.zorgkantoorregionaam)
            this.ddt_jaar_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.jaar)
            this.ddt_eenpersoons = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => (d.percentage))
            this.ddt_zorgkantoorregionaam_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.zorgkantoorregionaam)
            this.ddt_meerpersoons = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => (d.percentage))
            this.ddt_jaar_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.jaar)
            this.ddt_zorgkantoorregionaam_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.zorgkantoorregionaam)
            this.ddt_zorginstelling = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => (d.percentage))
            this.ddt_jaar_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.jaar)
            this.load.graphTwo=true
          }
        )
      }
      else if(level === 'landelijk'){
        this.$http.get(`/api/zorgkantoor_huishouden/get_data/?landelijk=True`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.ddt_jaar_ep_land = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.jaar)
            this.zorgkantoorregionaam_ep_land = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.zorgkantoorregionaam)
            this.eenpersoons_land = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => (d.percentage))
             this.ddt_jaar_mp_land = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.jaar)
            this.zorgkantoorregionaam_mp_land = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.zorgkantoorregionaam)
            this.meerpersoons_land = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => (d.percentage))
             this.ddt_jaar_i_land = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.jaar)
            this.zorgkantoorregionaam_zi_land = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.zorgkantoorregionaam)
            this.zorginstelling_land = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => (d.percentage))

            response.data.sort((a, b) => a.zorgkantoorregionaam - b.zorgkantoorregionaam )
            this.huishouden_groep_alle = response.data.map(d => d.typehuishouden)
            this.jaar_huishouden_alle = response.data.map(d => d.jaar)
            this.percentage_huishouden_alle = response.data.map(d => (d.percentage).toFixed(1))

            this.load.graphSix=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_tijdtotopname/get_data/?landelijk=True`).then(
        (response) => {
          response.data.sort((a,b) => a.jaar-b.jaar)
          response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')

          this.ttp_jaar_42_land = response.data.filter(x => x.tijd_tot_opname == "0-42 dagen").map(d => d.jaar)
          this.zorgkantoorregio42_land = response.data.filter(x => x.tijd_tot_opname =="0-42 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen42_land = response.data.filter(x => x.tijd_tot_opname =="0-42 dagen").map(d => (d.percentage))
          this.ttp_jaar_91_land = response.data.filter(x => x.tijd_tot_opname == "43-91 dagen").map(d => d.jaar)
          this.zorgkantoorregio91_land = response.data.filter(x => x.tijd_tot_opname =="43-91 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen91_land = response.data.filter(x => x.tijd_tot_opname =="43-91 dagen").map(d => (d.percentage))
          this.ttp_jaar_180_land = response.data.filter(x => x.tijd_tot_opname == "92-180 dagen").map(d => d.jaar)
          this.zorgkantoorregio180_land = response.data.filter(x => x.tijd_tot_opname =="92-180 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen180_land = response.data.filter(x => x.tijd_tot_opname =="92-180 dagen").map(d => (d.percentage))
          this.ttp_jaar_365_land = response.data.filter(x => x.tijd_tot_opname == "181-365 dagen").map(d => d.jaar)
          this.zorgkantoorregio365_land = response.data.filter(x => x.tijd_tot_opname =="181-365 dagen").map(d => d.zorgkantoorregionaam)
          this.dagen365_land = response.data.filter(x => x.tijd_tot_opname =="181-365 dagen").map(d => (d.percentage))
          this.ttp_jaar_366n_land = response.data.filter(x => x.tijd_tot_opname == "> 365 dagen/geen opname").map(d => d.jaar)
          this.zorgkantoorregio366n_land = response.data.filter(x => x.tijd_tot_opname =="> 365 dagen/geen opname").map(d => d.zorgkantoorregionaam)
          this.dagen366n_land = response.data.filter(x => x.tijd_tot_opname =="> 365 dagen/geen opname").map(d => (d.percentage))

          response.data.sort((a, b) => a.zorgkantoorregionaam - b.zorgkantoorregionaam )
          this.tijdtotopname_groep_alle = response.data.map(d => d.tijd_tot_opname)
          this.jaar_tijdtotopname_alle = response.data.map(d => d.jaar)
          this.percentage_tijdtotopname_alle = response.data.map(d => (d.percentage).toFixed(1))
          this.load.graphSix=true
        }
      )
      }
      else {
        this.$http.get(`/api/dementienetwerk_huishouden/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a, b) => a.dementienetwerknaam - b.dementienetwerknaam )
            this.jaar_huishouden = response.data.map(d => d.jaar)
            this.regionaam_huishouden = response.data.map(d => d.dementienetwerknaam)
            this.typehuishouden_alle = response.data.map(d => d.typehuishouden)
            this.percentage_huishouden = response.data.map(d => d.percentage.toFixed(4))
            this.percentage_huishouden_perc = response.data.map(d => (d.percentage).toFixed(1))

            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
            this.zorgkantoorregionaam_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.dementienetwerknaam)
            this.eenpersoons = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => (d.percentage))
            this.zorgkantoorregionaam_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.dementienetwerknaam)
            this.meerpersoons = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => (d.percentage))
            this.zorgkantoorregionaam_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.dementienetwerknaam)
            this.zorginstelling = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => (d.percentage))
            this.load.graphOne=true
          }
        ),
        this.$http.get(`/api/dementienetwerk_huishouden/get_data/?netwerk=${this.$store.state.selectedRegion}`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
            this.ddt_zorgkantoorregionaam_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.dementienetwerknaam)
            this.ddt_jaar_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.jaar)
            this.ddt_eenpersoons = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => (d.percentage))
            this.ddt_zorgkantoorregionaam_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.dementienetwerknaam)
            this.ddt_meerpersoons = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => (d.percentage))
            this.ddt_jaar_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.jaar)
            this.ddt_zorgkantoorregionaam_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.dementienetwerknaam)
            this.ddt_zorginstelling = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => (d.percentage))
            this.ddt_jaar_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.jaar)
            this.load.graphTwo=true
          }
        )
      }
      this.$http.get(`/api/opname_herkomst/get_data/`).then(
        (response) => {
          this.jaar_thuis = response.data.filter(x => x.herkomst =='Thuis').map(d => d.jaar)
          this.thuis = response.data.filter(x => x.herkomst =='Thuis').map(d => (d.percentage))
          this.jaar_verpleeghuis = response.data.filter(x => x.herkomst =='Verpleeghuis').map(d => d.jaar)
          this.verpleeghuis = response.data.filter(x => x.herkomst =='Verpleeghuis').map(d => (d.percentage))
          this.jaar_overig = response.data.filter(x => x.herkomst =='Overig').map(d => d.jaar)
          this.overig = response.data.filter(x => x.herkomst =='Overig').map(d => (d.percentage))

          response.data.sort((a, b) => a.herkomst - b.herkomst)
          this.jaar_opnameherkomst = response.data.map(d => d.jaar)
          this.opnameherkomst_alle = response.data.map(d => d.herkomst)
          this.percentage_opnameherkomst = response.data.map(d => (d.percentage).toFixed(1))

          this.load.graphFour=true
        }
      ),
      this.$http.get(`/api/opname_bestemming/get_data/`).then(
        (response) => {
          this.jaar_thuis_bst = response.data.filter(x => x.bestemming =='Thuis').map(d => d.jaar)
          this.thuis_bst = response.data.filter(x => x.bestemming =='Thuis').map(d => (d.percentage))
          this.jaar_verpleeghuis_bst = response.data.filter(x => x.bestemming =='Verpleeghuis').map(d => d.jaar)
          this.verpleeghuis_bst = response.data.filter(x => x.bestemming =='Verpleeghuis').map(d => (d.percentage))
          this.jaar_overig_bst = response.data.filter(x => x.bestemming =='Overig').map(d => d.jaar)
          this.overig_bst = response.data.filter(x => x.bestemming =='Overig').map(d => (d.percentage))
          this.jaar_hospice_bst = response.data.filter(x => x.bestemming =='Hospice').map(d => d.jaar)
          this.hospice_bst = response.data.filter(x => x.bestemming =='Hospice').map(d => (d.percentage))
          this.jaar_revalidatiecentrum_bst = response.data.filter(x => x.bestemming =='Revalidatie').map(d => d.jaar)
          this.revalidatiecentrum_bst = response.data.filter(x => x.bestemming =='Revalidatie').map(d => (d.percentage))

          response.data.sort((a, b) => a.herkomst - b.herkomst)
          this.jaar_opnamebestemming = response.data.map(d => d.jaar)
          this.opnamebestemming_alle = response.data.map(d => d.bestemming)
          this.percentage_opnamebestemming = response.data.map(d => (d.percentage).toFixed(1))

          this.load.graphFive=true
        }
      )
    }
  },
  mounted () {
        this.loadAllData(this.selectedLevel),
        this.loadTijdTotOpname()
      }
}

</script>

<style scoped>

.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}


</style>